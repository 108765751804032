import type { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import type { ApplicantLoginFlow } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';

import AccountTypeSelectionStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/AccountTypeSelectionStep';

const EmptyStep: FC = () => null;

const steps: Record<ApplicantLoginFlow, FC[]> = {
    'sign-in': [EmptyStep],
    'sign-up': [EmptyStep],
};

const MultiStepForm: FC = () => {
    const { flow, step } = useApplicantLoginContext();

    const ActiveStep = flow === null ? AccountTypeSelectionStep : steps[flow][step];

    return (
        <VSpacingContainer default={24}>
            <ActiveStep />
        </VSpacingContainer>
    );
};

export default MultiStepForm;
