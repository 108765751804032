import { useState, FC } from 'react';

import { VSpacing, Card } from '@hh.ru/magritte-ui';

import ApplicantLoginTitle from 'src/components/AccountLogin/components/Title/ApplicantLoginTitle';
import useLoginForm from 'src/components/AccountLogin/hooks/useLoginForm';
import SocialNewIcons from 'src/components/SignInSignUpFlow/SocialRounded';
import { useDisableSocialNetworkAuthExp } from 'src/hooks/useDisableSocialNetworkAuthExp';
import useIsIframeView from 'src/hooks/useIsIframeView';
import { useSelector } from 'src/hooks/useSelector';

import ApplicantLoginForm from 'src/components/AccountLogin/CombinedLoginCards/ApplicantLoginForm';

interface ApplicantLoginCardProps {
    onSignUp?: () => void;
}

const ApplicantLoginCard: FC<ApplicantLoginCardProps> = ({ onSignUp }) => {
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const isIframeView = useIsIframeView();
    const [applicantSignInStep, setApplicantSignInStep] = useState<string>();
    const [isTileView, setIsTileView] = useState(true);
    const isDisableSocialNetworkAuthExp = useDisableSocialNetworkAuthExp();
    const shouldShowSocialNetworks = !!socialNetworks && !isIframeView && !isDisableSocialNetworkAuthExp;
    const loginFormData = useLoginForm({ actionName: 'applicant-login' });

    const form = (
        <ApplicantLoginForm
            onSignUp={() => onSignUp?.()}
            onNextStep={(step) => {
                setApplicantSignInStep(step);
                setIsTileView(false);
            }}
            applicantSignInStep={applicantSignInStep}
            loginFormData={loginFormData}
        />
    );

    if (!isTileView) {
        return form;
    }

    return (
        <Card stretched padding={32} borderRadius={40} showBorder>
            <ApplicantLoginTitle step={loginFormData.step} verificationType={loginFormData.verificationType} />
            {form}
            {shouldShowSocialNetworks && (
                <>
                    <VSpacing default={24} />
                    {!!socialNetworks && <SocialNewIcons socialNetworks={socialNetworks} />}
                </>
            )}
        </Card>
    );
};

export default ApplicantLoginCard;
