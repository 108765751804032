import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing } from '@hh.ru/magritte-ui';
import { FormSeparator } from 'bloko/blocks/form';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ApplicantAuthMethodSwitcher from 'src/components/AccountLogin/components/ApplicantAuthMethodSwitcher';
import FormSubmit from 'src/components/Forms/Submit';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import SignInFormStep from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';

interface ApplicantAuthFormByCodeProps {
    onClickByPassword?: () => void;
    onSignup?: () => void;
    isSignupPage?: boolean;
    next: () => void;
    errorPageLogin?: boolean;
}

const TrlKeys = {
    action: 'login.action.auth',
    actionByPassword: 'login.action.by.password',
};

const ApplicantAuthFormByCode: TranslatedComponent<ApplicantAuthFormByCodeProps> = ({
    trls,
    onClickByPassword,
    onSignup,
    isSignupPage,
    errorPageLogin,
    ...props
}) => {
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const backurl = useSelector(({ authUrl }) => authUrl?.backurl);
    const isMagritte = useMagritte();

    useEffect(() => {
        Analytics.sendHHEvent('element_shown', {
            elementName: 'applicant_authorization',
            type: 'by_code',
            vacancyId,
            isExpanded: true,
        });
    }, [vacancyId]);
    return (
        <SignInFormStep
            isSignupPage={isSignupPage}
            {...props}
            callbackSubmit={(errors) => {
                if (!errors) {
                    onSignup?.();
                }
            }}
            backurl={backurl}
            render={({ renderLogin, isFetching, renderCaptcha }) => (
                <>
                    {renderLogin()}
                    {renderCaptcha()}
                    {isMagritte ? <VSpacing default={12} /> : <FormSeparator />}
                    <div
                        className={`account-login-actions ${
                            isMagritte && !errorPageLogin ? 'account-login-actions_column' : ''
                        }`}
                    >
                        <FormSubmit data-qa="account-signup-submit" isFetching={isFetching}>
                            {trls[TrlKeys.action]}
                        </FormSubmit>
                        <ApplicantAuthMethodSwitcher
                            data-qa="expand-login-by-password"
                            watchedFieldName={isSignupPage ? undefined : 'login'}
                            onClick={onClickByPassword}
                        >
                            {trls[TrlKeys.actionByPassword]}
                        </ApplicantAuthMethodSwitcher>
                    </div>
                </>
            )}
        />
    );
};

export default translation(ApplicantAuthFormByCode);
