import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { TopLevelSite } from 'src/models/topLevelSite';

const TrlKeys = {
    copyright: {
        [TopLevelSite.BY]: 'footer.tutby.copyrights',
        general: 'index.footer.menuitem.copyright',
    },
};

const AccountLoginLayoutCopyright: TranslatedComponent = ({ trls }) => {
    const topLevelSite = useSelector((store) => store.topLevelSite);

    const content = (
        <span suppressHydrationWarning>
            {format(trls[TrlKeys.copyright[topLevelSite === TopLevelSite.BY ? TopLevelSite.BY : 'general']], {
                '{0}': new Date().getFullYear().toString(),
            })}
        </span>
    );

    return (
        <Text style="primary" typography="label-2-regular" data-qa="copyright">
            {content}
        </Text>
    );
};

export default translation(AccountLoginLayoutCopyright);
