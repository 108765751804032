import { RouterLocation } from 'connected-react-router';

import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';
import type { UserType } from 'src/models/userType';

const getExpandURL = (userType: UserType, location: RouterLocation<unknown>) => {
    const url = urlParser(`${location.pathname}${location.search}`);
    url.params.role = userType;
    return url.href;
};

interface UseAccountTypeSwitchHook {
    onSwitchTo: (userType: UserType) => void;
}

const useAccountTypeSwitch = (): UseAccountTypeSwitchHook => {
    const location = useSelector((state) => state.router.location);

    const onSwitchTo = (userType: UserType) => {
        window.location.assign(getExpandURL(userType, location));
    };

    return { onSwitchTo };
};

export default useAccountTypeSwitch;
