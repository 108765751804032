import { useIsRussia } from 'src/hooks/useCountries';
import useExperiment from 'src/hooks/useExperiment';
import { useIsHhru } from 'src/hooks/useSites';

export function useDisableSocialNetworkAuthExp(): boolean {
    const isHHRu = useIsHhru();
    const isRussia = useIsRussia();
    const isExpConditionMet = isHHRu && isRussia;
    const isExpEnabled = useExperiment('disable_social_network_auth', isExpConditionMet);

    return isExpEnabled;
}
