import PropTypes from 'prop-types';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const Postpone = ({ to, onClick, ...props }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { addNotification } = useNotification();

    const handleClick = async (event) => {
        event.preventDefault();
        onClick?.();
        const href = event.currentTarget.href;
        if (!vacancyId) {
            window.location.assign(href);
        } else {
            try {
                await fetcher.put('/vacancy/postponed', null, { params: { vacancyId } });
            } catch (error) {
                defaultRequestErrorHandler(error, addNotification);
                return;
            }
            window.location.assign(`${href}&postponed=`);
        }
    };

    return <a {...props} href={to} onClick={handleClick} />;
};

Postpone.propTypes = {
    to: PropTypes.string,
    push: PropTypes.func,
    showDefaultError: PropTypes.func,
    vacancyId: PropTypes.number,
    onClick: PropTypes.func,
};

export default Postpone;
