import { type FC } from 'react';

import { Card, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';

import Header from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header';
import MultiStepForm from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm';
import ApplicantLoginProvider from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';

const ExpApplicantLoginCard: FC = () => {
    const { isGtXS } = useBreakpoint();
    const { isApplicantRenewedAuthExpD } = useApplicantRenewedAuthExp();

    return (
        <Card showBorder={isGtXS} stretched padding={24} borderRadius={40}>
            <ApplicantLoginProvider>
                {!isApplicantRenewedAuthExpD && <Header />}
                <VSpacing default={isApplicantRenewedAuthExpD ? 12 : 24} />
                <MultiStepForm />
            </ApplicantLoginProvider>
        </Card>
    );
};

export default ExpApplicantLoginCard;
