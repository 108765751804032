import { createContext, useCallback, useContext, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import type { ApplicantLoginFlow } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';

interface ApplicantLoginContextProps {
    step: number;
    flow: ApplicantLoginFlow | null;
    onNextStep: VoidFunction;
    onPrevStep: VoidFunction;
    onFlowChange: (value: ApplicantLoginFlow | null) => void;
}

const ApplicantLoginContext = createContext<ApplicantLoginContextProps | null>(null);

const ApplicantLoginProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isApplicantRenewedAuthExpC } = useApplicantRenewedAuthExp();

    const [flow, setFlow] = useState<ApplicantLoginContextProps['flow']>(isApplicantRenewedAuthExpC ? 'sign-in' : null);
    const [step, setStep] = useState<ApplicantLoginContextProps['step']>(0);

    const onNextStep: ApplicantLoginContextProps['onNextStep'] = useCallback(() => {
        setStep((prev) => prev + 1);
    }, []);

    const onPrevStep: ApplicantLoginContextProps['onPrevStep'] = useCallback(() => {
        setStep((prev) => Math.max(prev - 1, 0));
    }, []);

    const onFlowChange: ApplicantLoginContextProps['onFlowChange'] = useCallback((value) => {
        setFlow(value);
        setStep(0);
    }, []);

    const value: ApplicantLoginContextProps = useMemo(
        () => ({
            flow,
            step,
            onNextStep,
            onPrevStep,
            onFlowChange,
        }),
        [flow, step, onNextStep, onPrevStep, onFlowChange]
    );

    return <ApplicantLoginContext.Provider value={value}>{children}</ApplicantLoginContext.Provider>;
};

const useApplicantLoginContext = (): ApplicantLoginContextProps => {
    const context = useContext<ApplicantLoginContextProps | null>(ApplicantLoginContext);

    if (!context) {
        throw new Error('Calling useApplicantLoginContext outside of ApplicantLoginProvider');
    }

    return context;
};

export { useApplicantLoginContext, ApplicantLoginContext };
export default ApplicantLoginProvider;
