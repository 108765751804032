import { useState } from 'react';

import { VSpacing, Card, Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LoginForm, { useLoginForm } from 'src/components/AccountLogin/Form';
import EmployerLoginTitle from 'src/components/AccountLogin/components/Title/EmployerLoginTitle';
import useAuthMethodParam from 'src/components/AccountLogin/hooks/useAuthMethodParam';
import { AuthMethod, LoginFormValues, LoginSteps } from 'src/components/AccountLogin/types';
import SocialButtons from 'src/components/SignInSignUpFlow/SocialButtons';
import translation from 'src/components/translation';
import useRegisterEmployerLink from 'src/hooks/useRegisterEmployerLink';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    actionSignup: 'employer.login.action.signup',
    registration: 'registration.form.register.self',
    submit: 'login.action.signup.submit',
};

interface EmployerLoginCardProps {
    onNextStep?: () => void;
}

const EmployerLoginCard: TranslatedComponent<EmployerLoginCardProps> = ({ trls, onNextStep }) => {
    const authMethodParam = useAuthMethodParam();
    const initialAuthMethod = authMethodParam || AuthMethod.ByCode;
    const [formValues, setFormValues] = useState<LoginFormValues>();
    const { elementRef, dataQa: employerLinkDataQa, to, onClick } = useRegisterEmployerLink();
    const { isEmployerLogin: isRedirectedFromApplicantForm } = useSelector(({ router }) => router.location.query);
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const loginFormData = useLoginForm({
        actionName: 'employer-login',
        isEmployerForm: true,
        initialValues: formValues,
        initialAuthMethod: isRedirectedFromApplicantForm ? AuthMethod.ByPassword : initialAuthMethod,
        onStepChange: () => onNextStep?.(),
        onSubmitResult: ({ values }) => {
            setFormValues(values);
        },
    });

    return (
        <Card stretched padding={32} borderRadius={40} showBorder>
            <EmployerLoginTitle
                step={loginFormData.step}
                verificationType={loginFormData.verificationType}
                username={loginFormData.initialValues.username}
            />
            <LoginForm {...loginFormData}>
                {!isRedirectedFromApplicantForm && <LoginForm.Username />}
                {loginFormData.authMethod === AuthMethod.ByPassword && <LoginForm.Password />}
                <LoginForm.Captcha />
                <LoginForm.Error />
                {loginFormData.authMethod === AuthMethod.ByPassword && <LoginForm.PasswordRecovery />}
                <VSpacing default={loginFormData.authMethod === AuthMethod.ByPassword ? 24 : 12} />
                <LoginForm.Submit customSubmitText={trls[TrlKeys.submit]} />
                {!isRedirectedFromApplicantForm && (
                    <>
                        <VSpacing default={12} />
                        <LoginForm.AuthMethodSwitcher mode="button" />
                    </>
                )}
            </LoginForm>
            {loginFormData.step === LoginSteps.Login && !isRedirectedFromApplicantForm && (
                <>
                    {!!socialNetworks?.length && <SocialButtons socialNetworks={socialNetworks} />}
                    <VSpacing default={12} />
                    <Button
                        Element={Link}
                        mode="tertiary"
                        style="accent"
                        size="large"
                        stretched
                        data-qa={employerLinkDataQa}
                        onClick={onClick}
                        to={to}
                        ref={elementRef}
                    >
                        {trls[TrlKeys.registration]}
                    </Button>
                </>
            )}
        </Card>
    );
};

export default translation(EmployerLoginCard);
