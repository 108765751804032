import { useRef, useState, type FC } from 'react';

import { BottomSheet, Tooltip, useBreakpoint } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import HeaderIconButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header/HeaderIconButton';

const HeaderHint: FC = () => {
    const [isHintVisible, setIsHintVisible] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);
    const { isGtS } = useBreakpoint();

    const handleButtonClick = (): void => {
        setIsHintVisible(!isHintVisible);
    };

    const handleHintClose = (): void => {
        setIsHintVisible(false);
    };

    const content = 'Здесь будет добавлен текст'; // Ждем текст от дизайнера

    return (
        <>
            <HeaderIconButton
                ref={ref}
                ariaLabel="hint-button"
                icon={<QuestionCircleOutlinedSize24 />}
                onClick={handleButtonClick}
            />
            {isGtS ? (
                <Tooltip activatorRef={ref} visible={isHintVisible} placement="bottom-center" onClose={handleHintClose}>
                    {content}
                </Tooltip>
            ) : (
                <BottomSheet visible={isHintVisible} onClose={handleHintClose}>
                    {content}
                </BottomSheet>
            )}
        </>
    );
};

export default HeaderHint;
