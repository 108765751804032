import { VSpacing, Card, Title, Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface ApplicantToEmployerProps {
    onSwitch: () => void;
}

const TrlKeys = {
    header: 'employer.login.header',
    subheader: 'employer.login.subheader',
    action: 'employer.login.action.find',
};

const ApplicantToEmployer: TranslatedComponent<ApplicantToEmployerProps> = ({ trls, onSwitch }) => {
    return (
        <Card stretched padding={32} borderRadius={40} showBorder>
            <Title Element="h2" size="small" description={trls[TrlKeys.subheader]}>
                {trls[TrlKeys.header]}
            </Title>
            <VSpacing default={20} />
            <Button onClick={onSwitch} mode="secondary" style="accent" stretched>
                {trls[TrlKeys.action]}
            </Button>
        </Card>
    );
};

export default translation(ApplicantToEmployer);
