/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { AppStore } from 'src/app/store';
import { useSelector } from 'src/hooks/useSelector';

interface Props {
    watchedFieldName?: string;
    handler: () => void;
    valueSelector: (state: AppStore) => string | undefined;
    onDispatch: (dispatchedValue: string) => { type: string };
}

const useDispatchedFormField = ({ watchedFieldName = '', handler, valueSelector, onDispatch }: Props) => {
    const field = useField<string>(watchedFieldName);
    const dispatch = useDispatch();
    const value = useSelector(valueSelector);
    const isCalled = useRef(false);
    const dispatchedValue = field.input.value;

    useEffect(() => {
        if (isCalled.current && dispatchedValue === value) {
            isCalled.current = false;
            handler();
        }
    }, [handler, value, dispatchedValue]);

    if (!watchedFieldName) {
        return handler;
    }

    return () => {
        if (value !== dispatchedValue) {
            dispatch(onDispatch(dispatchedValue));
            isCalled.current = true;
        } else {
            handler();
        }
    };
};

export default useDispatchedFormField;
